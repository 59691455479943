import React from 'react';
import { CSSTransitionGroup } from 'react-transition-group';

import { fetchAirlinePreferenceLines } from '../../../actions/channel/channel';

import { UbtBoundary, ChannelUbtGenerator, CHANNEL_UBT_GROUP_TYPES, CHANNEL_UBT_KEY_TYPES } from '../../../ubt.v2/channel';
import { genOuterLowPriceTrackQuery } from '../../../sources/common/genOuterLowPriceTrackQuery';

const airlines = window.GlobalConfigs.newAirlinePreferenceConfig,
	airlinesSize = (airlines && airlines.length) || 0,
	airlineItemWidth = 107,
	airlinesWidth = airlinesSize * airlineItemWidth;

const formatWeek = (date) => {
	if (!date) return '';

	const weekArr = ['日', '一', '二', '三', '四', '五', '六'];

	const dateArr = date.substr(0, 10).split('-');
	const year = parseInt(dateArr[0], 10);
	const month = parseInt(dateArr[1], 10) - 1;
	const day = parseInt(dateArr[2], 10);

	const weekIndex = new Date(year, month, day).getDay();

	return '周' + weekArr[weekIndex];
};

const formatRate = (rate) => {
	if (!rate || rate == 1) return '';
	if (rate && rate < 1) return `${(rate * 10).toFixed(1)}折`;
};

const showField = (airlineIndex, fieldName) => {
	return airlines && airlines[airlineIndex] && airlines[airlineIndex][fieldName];
};

class AirlinePreferenceContainer extends React.Component<any, any> {
	constructor(props) {
		super(props);

		this.airlineContent = null;

		this.state = {
			airlineCode: '',
			tabIndex: 0,
			airlineIndex: 0,
			departureIndex: 0,
			flightChoice: [],
		};
		this.showPrev = this.showPrev.bind(this);
		this.showNext = this.showNext.bind(this);
		this.fetchLinesByAirlineAndDeparture = this.fetchLinesByAirlineAndDeparture.bind(this);
	}

	componentDidMount() {
		const airlineIndex = 0;
		const departureIndex = 0;
		this.fetchLinesByAirlineAndDeparture(airlineIndex, departureIndex);
	}

	searchDomestic(flight) {
		const { price } = flight;
		let extTrackQuery = genOuterLowPriceTrackQuery({ price, source: 'channel_bottom_price', containsTax: false });

		if (flight.dcityCode && flight.acityCode && flight.departDate) {
			window.location.href =
				`//flights.ctrip.com/itinerary/oneway/${flight.dcityCode.toLowerCase()}-${flight.acityCode.toLowerCase()}` +
				`?date=${flight.departDate.substr(0, 10)}&airline=${
					this.state.airlineCode
				}&${extTrackQuery}#ctm_ref=fld_hp_as_all_t_1`;
		}

		CHANNEL_UBT_KEY_TYPES.SEND_NOW_CLICK.value.process({
			tag: { text: '航司特惠航线点选', type: 'searchDomestic' },
		});
	}

	fetchLinesByAirlineAndDeparture(airlineIndex, departureIndex) {
		if (!airlines) return;

		const cityPairList: any[] = [];
		const airlineCode = airlines[airlineIndex]['airlineCode'];
		const { dCityAndACity } = airlines[airlineIndex]['cityPairList'][departureIndex];
		dCityAndACity.forEach((item) => {
			const cityPair: any = {};
			cityPair.dcityCode = item.substr(0, 3);
			cityPair.acityCode = item.substr(4);

			cityPairList.push(cityPair);
		});

		fetchAirlinePreferenceLines(airlineCode, cityPairList).then((json) => {
			if (json && json.data != null) {
				this.setState({ airlineCode, flightChoice: json.data });
			}
		});
	}

	chooseAirline(index) {
		this.setState(
			{
				airlineIndex: index,
				departureIndex: 0,
			},
			this.fetchLinesByAirlineAndDeparture(index, 0)
		);

		CHANNEL_UBT_KEY_TYPES.SEND_NOW_CLICK.value.process({
			tag: {
				text: '点击航司切换航司',
				type: 'chooseAirline',
				airlineCode: this.state.airlineCode,
			},
		});
	}

	chooseDeparture(departureIndex) {
		const airlineIndex = this.state.airlineIndex;

		this.setState(
			{
				departureIndex,
			},
			this.fetchLinesByAirlineAndDeparture(airlineIndex, departureIndex)
		);

		CHANNEL_UBT_KEY_TYPES.SEND_NOW_CLICK.value.process({
			tag: { text: '航司出发地切换', type: 'chooseDeparture' },
		});
	}

	showPrev() {
		if (this.state.tabIndex > 0) {
			this.setState(
				{
					airlineIndex: (this.state.tabIndex - 1) * 10,
					departureIndex: 0,
					tabIndex: this.state.tabIndex - 1,
				},
				() => {
					this.fetchLinesByAirlineAndDeparture(this.state.airlineIndex, 0);
					this.airlineContent.style.left = `${-1060 * this.state.tabIndex}px`;
				}
			);
		} else {
			this.setState(
				{
					airlineIndex: 20,
					departureIndex: 0,
					tabIndex: 2,
				},
				() => {
					this.fetchLinesByAirlineAndDeparture(20, 0);
					this.airlineContent.style.left = '-2120px';
				}
			);
		}

		CHANNEL_UBT_KEY_TYPES.SEND_NOW_CLICK.value.process({
			tag: { text: '航司tab左切换', type: 'showPrev' },
		});
	}

	showNext() {
		if (this.state.tabIndex < 2) {
			this.setState(
				{
					airlineIndex: (this.state.tabIndex + 1) * 10,
					departureIndex: 0,
					tabIndex: this.state.tabIndex + 1,
				},
				() => {
					this.fetchLinesByAirlineAndDeparture(this.state.airlineIndex, 0);
					this.airlineContent.style.left = `${-1060 * this.state.tabIndex}px`;
				}
			);
		} else {
			this.setState(
				{
					airlineIndex: 0,
					departureIndex: 0,
					tabIndex: 0,
				},
				() => {
					this.fetchLinesByAirlineAndDeparture(0, 0);
					this.airlineContent.style.left = 0;
				}
			);
		}

		CHANNEL_UBT_KEY_TYPES.SEND_NOW_CLICK.value.process({
			tag: { text: '航司tab右切换', type: 'showNext' },
		});
	}

	render() {
		return (
			<UbtBoundary
				tracker={ChannelUbtGenerator(
					CHANNEL_UBT_GROUP_TYPES.AIRLINE_PREFERENCE,
					CHANNEL_UBT_KEY_TYPES.AIRLINE_PREFERENCE_SHOW,
					{
						text: '显示航司特惠',
					}
				)}
			>
				<div className="airline_preference">
					<h2 className="preference-title">航空公司特惠专区</h2>
					<div className="airline-block">
						<div className="airline-box">
							<div className="prev-block">
								<div className="prev-btn" onClick={this.showPrev}>
									<span className="action-prev">&#xe61a;</span>
								</div>
							</div>
							<div
								className="airline-content"
								ref={(node) => (this.airlineContent = node)}
								style={{ width: `${airlinesWidth}px` }}
							>
								{airlines &&
									airlines.map((airline, index) => (
										<div
											key={index}
											className={`airline-item${
												this.state.airlineIndex === index ? ' active' : ''
											}`}
											onClick={this.chooseAirline.bind(this, index)}
										>
											<img
												className="airline-logo"
												src={airline.tabLogo}
												alr={airline.airlineName}
											/>
											<div className="airline-name">{airline.airlineName}</div>
										</div>
									))}
							</div>
							<div className="next-block" ref={(nextBlock) => (this.nextBlock = nextBlock)}>
								<div className="next-btn" onClick={this.showNext}>
									<span className="action-next">&#xe600;</span>
								</div>
							</div>
						</div>
						<div className="airline-info">
							<img
								className="airline-logo"
								src={showField(this.state.airlineIndex, 'contentLogo')}
								alt=""
							/>
							<div className="airline-history">
								<div
									className="history-text"
									dangerouslySetInnerHTML={{
										__html: showField(this.state.airlineIndex, 'briefIntroduction'),
									}}
								></div>
								<div className="tooltip history-detail">
									查看更多
									<span
										className="tooltip-text"
										dangerouslySetInnerHTML={{
											__html: showField(this.state.airlineIndex, 'introduction'),
										}}
									></span>
								</div>
							</div>
						</div>
						<div className="line-isolation"></div>
						<div className="airline-flight">
							<div className="flight-departure">
								<div className="departure-title">出发地：</div>
								{showField(this.state.airlineIndex, 'dCities').map((dCity, index) => (
									<div
										key={index}
										className={`departure-item${
											this.state.departureIndex === index ? ' active' : ''
										}`}
										onClick={this.chooseDeparture.bind(this, index)}
									>
										{dCity}
									</div>
								))}
							</div>
							<div className="flight-choice">
								<CSSTransitionGroup
									transitionName="departure-list-animate"
									transitionEnter={true}
									transitionLeave={true}
									transitionEnterTimeout={300}
									transitionLeaveTimeout={300}
								>
									{this.state.flightChoice &&
										this.state.flightChoice.map((flight, index) => {
											if (index < 8) {
												return (
													<div
														className="ow-item"
														key={index}
														onClick={this.searchDomestic.bind(this, flight)}
													>
														<div className="item-top">
															<span className="flight">
																<div className="depart">{flight.dcityName}</div>
																<i className="iconf-ow-arrow">&#xe672;</i>
																<div className="arrival">{flight.acityName}</div>
															</span>
															<span className="price">
																<span className="price-tag">￥</span>
																<span className="price-amoumt">{flight.price}</span>起
															</span>
														</div>
														<div className="item-main">
															<span className="date">
																<span className="go">
																	{flight.departDate &&
																		flight.departDate.substr(0, 10)}
																</span>
																<span className="back">
																	{formatWeek(flight.departDate)}
																</span>
															</span>
															<span className="discount">{formatRate(flight.rate)}</span>
														</div>
													</div>
												);
											}
										})}
								</CSSTransitionGroup>
							</div>
						</div>
					</div>
				</div>
			</UbtBoundary>
		);
	}
}

export default AirlinePreferenceContainer;
